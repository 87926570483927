import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Divider from "../components/Divider"
import EmailListSignup from "../components/EmailListSignup"
import MarkdownComponent from "../components/MarkdownComponent"

interface Props {
	data: {
		allMdx: { edges: PostEdge[] }
		site: {
			siteMetadata: {
				title: string
			}
		}
	}
}

const BlogIndex = ({ data }: Props) => {
	const posts = data.allMdx.edges

	return (
		<Layout>
			<SEO title="Home" />
			<MarkdownComponent slug="home" />
			<Divider />
			<h2>Recent Articles</h2>
			{posts.map(({ node }) => {
				const title = node.frontmatter.title || node.fields.slug
				return (
					<div key={node.fields.slug}>
						<h3 style={{ marginBottom: rhythm(1 / 4), }} >
							<Link to={node.fields.slug}>
								{title}
							</Link>
						</h3>
						<p
							dangerouslySetInnerHTML={{
								__html: node.frontmatter.description || node.excerpt,
							}}
						/>
					</div>
				)
			})}
			<div style={{ marginBottom: 50, textAlign: 'center' }}>
				<Link to="/articles">All Articles</Link>
			</div>
			<EmailListSignup />
			<Divider />
			<Bio />
		</Layout>
	)
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
		sort: { fields: [frontmatter___date], order: DESC }
		filter: {fields: {draft: {ne: true}}, collection: { eq: "blog" }}
		limit: 3
	) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`

interface PostEdge {
	node: {
		excerpt: string;
		fields: {
			slug: string
		};
		frontmatter: {
			date: string;
			title: string;
			description: string;
		}
	};
}